import { tokens } from "@bees-grow-shared/theme";
import { Button as ButtonHexa } from "@hexa-ui/components";
import styled from "styled-components";

export const ActionList = styled.div`
  display: flex;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Button = styled(ButtonHexa)`
  max-width: 5.5rem;
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  color: ${tokens.color.foundation.txt.default};
`;
