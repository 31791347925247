import { useIntl } from "react-intl";

import { UpdateButton } from "@bees-grow-shared/components";
import {
  lastOrdersSharedService,
  useDefaultAnalyticsData,
} from "@bees-grow-shared/services";

import segmentAnalytics from "@/config/typewriter";

import * as Styled from "./Header.styles";

interface HeaderProps {
  getLastOrders: () => void;
  isLastOrdersError: boolean;
  isLastOrdersEmpty: boolean;
}

export function Header({
  getLastOrders,
  isLastOrdersError,
  isLastOrdersEmpty,
}: Readonly<HeaderProps>) {
  const lastOrdersService = lastOrdersSharedService();
  const { formatMessage } = useIntl();
  const defaultData = useDefaultAnalyticsData();

  const handleGoToFullTable = () => {
    segmentAnalytics.buttonClicked({
      button_label: formatMessage({ id: "LastOrders.SHOW_FULL_TABLE" }),
      button_name: "last_orders_full_table",
      calling_method: "CLICK",
      url: null,
      screen_name: "account",
      ...defaultData,
    });
    lastOrdersService.setLastOrdersState({
      showLastOrderModal: true,
    });
  };

  const handleUpdateButton = () => {
    segmentAnalytics.buttonClicked({
      button_label: formatMessage({ id: "UpdateButton.TEXT" }),
      button_name: "update_preview",
      calling_method: "CLICK",
      url: null,
      screen_name: "account",
      ...defaultData,
    });
    getLastOrders();
    return;
  };

  const shouldShowActions = !isLastOrdersError && !isLastOrdersEmpty;

  return (
    <Styled.HeaderContainer>
      <Styled.Title size="H4" data-testid="last-orders-header">
        {formatMessage({ id: "LastOrders.PREVIEW_TITLE" })}
      </Styled.Title>
      {shouldShowActions && (
        <>
          <Styled.UpdateButtonContainer>
            <UpdateButton
              text={formatMessage({ id: "UpdateButton.TEXT" })}
              action={handleUpdateButton}
            />
          </Styled.UpdateButtonContainer>
          <Styled.Link
            hasUnderline={false}
            onClick={handleGoToFullTable}
            data-testid="full-table-link"
          >
            {formatMessage({ id: "LastOrders.SHOW_FULL_TABLE" })}
          </Styled.Link>
        </>
      )}
    </Styled.HeaderContainer>
  );
}
