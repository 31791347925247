import styled from "styled-components";

export const TableContainer = styled.div`
  tbody tr:nth-child(even) {
    background-color: #fafafa;
  }

  tbody tr:hover {
    background-color: #f2f2f2;
  }

  // Products & Total
  th:nth-child(6),
  th:nth-child(7) {
    div {
      justify-content: right;
    }
  }

  td:nth-child(7) {
    text-align: right;
  }

  // Placed on
  th:nth-child(5) {
    white-space: nowrap;
  }
`;
