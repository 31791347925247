import { TooltipGeneric } from "@bees-grow-shared/components";
import { useBlockTooltip } from "@bees-grow-shared/services";

import * as Styled from "./Text.styles";

interface TextProps {
  tooltipText: string;
  text: string;
  weight?: "semibold" | "normal" | "medium";
}
export default function Text({
  tooltipText,
  text,
  weight = "normal",
}: TextProps) {
  const { refContent, blockByWidth } = useBlockTooltip();

  return blockByWidth ? (
    <Styled.Text ref={refContent} weight={weight}>
      {text}
    </Styled.Text>
  ) : (
    <TooltipGeneric placement="top" text={tooltipText}>
      <Styled.Text ref={refContent} weight={weight}>
        {text}
      </Styled.Text>
    </TooltipGeneric>
  );
}
