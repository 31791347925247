import { useIntl } from "react-intl";

import { Heading } from "@hexa-ui/components";

export default function TaskHeader() {
  const { formatMessage } = useIntl();

  return (
    <Heading size="H4" data-testid="task-header">
      {formatMessage({ id: "Tasks.TITLE" })}
    </Heading>
  );
}
