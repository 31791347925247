import { useIntl } from "react-intl";

import { useToast } from "@bees-grow-shared/business-components";
import {
  accountSharedService,
  ClassificationEnum,
  phoneRankSharedService,
  PhoneRequestType,
  PhoneTypeEnum,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { z } from "zod";

import segmentAnalytics from "@/config/typewriter";
import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

const phoneRankSchema = z.object({
  phone: z.string().refine((value) => {
    const phoneRegex = /^\+?\d+(\s\d+)*$/;
    return phoneRegex.test(value);
  }),
  name: z.string().min(1),
});

type PhoneRankSchema = z.infer<typeof phoneRankSchema>;

interface UsePhoneRankProps {
  handlePhoneRankAction: (data: PhoneRankSchema) => void;
  phoneParserOnBlur: (value: React.FocusEvent<HTMLInputElement>) => string;
  phoneParserOnChange: (value: React.FormEvent<HTMLInputElement>) => string;
  phoneRankSchema: z.ZodObject<
    {
      phone: z.ZodEffects<z.ZodString, string, string>;
      name: z.ZodString;
    },
    "strip",
    z.ZodTypeAny,
    {
      phone?: string;
      name?: string;
    }
  >;
}

export function usePhoneRankForm(): UsePhoneRankProps {
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();
  const toast = useToast();
  const { handleCloseModal, modalData } = usePhoneManagerModal();

  const { formatMessage } = useIntl();

  const { accountId, vendorId } = useSharedUnit(accountService);
  const defaultData = useDefaultAnalyticsData();

  const handlePhoneRankAction = (data: PhoneRankSchema) => {
    const phone: PhoneRequestType = {
      contactName: data.name,
      phoneNumber: data.phone,
      classification: ClassificationEnum.INPUTTED,
      phoneType: PhoneTypeEnum.INPUTTED_PHONE,
    };
    if (modalData) {
      segmentAnalytics.phoneEdited({
        ...defaultData,
        phone_number: phone.phoneNumber,
        phone_source: phone.phoneType,
        phone_owner: phone.contactName,
        old_number: modalData.phoneNumber,
        old_owner: modalData.phoneContact,
        screen_name: "Modal edit phone number",
      });
      phoneRankService
        .updatePhoneRank({
          params: { accountId, vendorId, phoneNumber: modalData?.phoneNumber },
          phone,
        })
        .then(() => {
          toast({
            showCloseButton: true,
            type: "success",
            message: formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.EDIT_SUCCESS_MESSAGE",
            }),
          });
        })
        .catch(() => {
          toast({
            showCloseButton: true,
            type: "error",
            message: formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.EDIT_ERROR_MESSAGE",
            }),
          });
        });
      handleCloseModal();
      return;
    }

    const phones: Array<PhoneRequestType> = [phone];
    segmentAnalytics.phoneAdded({
      ...defaultData,
      phone_number: phone.phoneNumber,
      phone_owner: phone.contactName,
      screen_name: "Modal save phone number",
    });
    phoneRankService
      .postPhoneRank({
        params: { accountId, vendorId },
        phones,
      })
      .then(() => {
        toast({
          showCloseButton: true,
          type: "success",
          message: formatMessage({
            id: "PhoneNumbers.AddEditPhoneModal.CREATE_SUCCESS_MESSAGE",
          }),
        });
      })
      .catch(() => {
        toast({
          showCloseButton: true,
          type: "error",
          message: formatMessage({
            id: "PhoneNumbers.AddEditPhoneModal.CREATE_ERROR_MESSAGE",
          }),
        });
      });
    handleCloseModal();
  };

  const phoneParserOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const cleanedValue = value
      .replace(/[^+\d\s]/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .replace(/(?!^\+)[^\d\s]/g, "")
      .replace(/^\+\s+/, "+")
      .trim();
    return cleanedValue;
  };

  const phoneParserOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const singleSpacedValue = value.replace(/[^\d\s+]/g, "");
    return singleSpacedValue;
  };

  return {
    handlePhoneRankAction,
    phoneRankSchema,
    phoneParserOnBlur,
    phoneParserOnChange,
  };
}
