import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Button, Modal as ModalHexa } from "@hexa-ui/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

import { usePhoneRankForm } from "./hooks/usePhoneRankForm";
import * as Styles from "./PhoneRankForm.styles";

export default function PhoneRankForm() {
  const { formatMessage } = useIntl();

  const {
    handlePhoneRankAction,
    phoneRankSchema,
    phoneParserOnBlur,
    phoneParserOnChange,
  } = usePhoneRankForm();
  const { Action, Cancel } = ModalHexa;
  const { modalData, handleCloseModal } = usePhoneManagerModal();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(phoneRankSchema),
    defaultValues: {
      phone: modalData?.phoneNumber,
      name: modalData?.phoneContact,
    },
  });

  return (
    <Styles.Form onSubmit={handleSubmit(handlePhoneRankAction)}>
      <Controller
        name="phone"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Styles.Input
            data-testid={"PhoneRankForm-form-input-phone"}
            id="phone"
            type="text"
            placeholder=""
            {...field}
            hasError={!!errors.phone}
            onBlur={(value: React.FocusEvent<HTMLInputElement>) => {
              setValue("phone", phoneParserOnBlur(value));
            }}
            onChange={(value: React.FormEvent<HTMLInputElement>) => {
              setValue("phone", phoneParserOnChange(value));
            }}
            label={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.FIELD_PHONE_NUMBER",
            })}
            hint={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.PHONE_HINT_TEXT",
            })}
            errorText={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.REQUIRED_FIELD",
            })}
          />
        )}
      />

      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Styles.Input
            data-testid={"PhoneRankForm-form-input-name"}
            id="name"
            type="text"
            placeholder=""
            {...field}
            hint={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.NAME_HINT_TEXT",
            })}
            label={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.FIELD_RESPONSIBLE",
            })}
            hasError={!!errors.name}
            errorText={formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.REQUIRED_FIELD",
            })}
          />
        )}
      />

      <Styles.ActionSection>
        <Cancel>
          <Button
            data-testid={"PhoneRankForm-form-button-cancel"}
            onClick={handleCloseModal}
            size="medium"
            variant="secondary"
          >
            {formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.BUTTON_CANCEL",
            })}
          </Button>
        </Cancel>
        <Action>
          <Button
            size="medium"
            variant="primary"
            type="submit"
            data-testid={"PhoneRankForm-form-button-save"}
          >
            {formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.BUTTON_SAVE",
            })}
          </Button>
        </Action>
      </Styles.ActionSection>
    </Styles.Form>
  );
}
