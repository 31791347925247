import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ScoreEnum } from "@bees-grow-shared/services";
import { FaceHappy, FaceNeutral, FaceSad } from "@hexa-ui/icons";

import * as Styles from "./RateMessage.styles";

interface PhoneRankListProps {
  score: ScoreEnum;
}

export default function RateMessage({ score }: Readonly<PhoneRankListProps>) {
  const { formatMessage } = useIntl();

  const getMessage = useMemo(() => {
    if (score === ScoreEnum.GOOD) {
      return formatMessage({ id: "PhoneNumbers.NUMBER_RATE.GOOD" });
    }
    if (score === ScoreEnum.BAD || score === ScoreEnum.INACTIVE) {
      return formatMessage({ id: "PhoneNumbers.NUMBER_RATE.BAD" });
    }

    return formatMessage({ id: "PhoneNumbers.NUMBER_RATE.NEUTRAL" });
  }, [score, formatMessage]);

  const getIcon = useMemo(() => {
    if (score === ScoreEnum.GOOD) {
      return <FaceHappy />;
    }
    if (score === ScoreEnum.BAD || score === ScoreEnum.INACTIVE) {
      return <FaceSad />;
    }

    return <FaceNeutral />;
  }, [score]);

  return (
    <Styles.ContainerRateMessage data-testid={`${RateMessage.name}`}>
      {getIcon}
      <Styles.Text data-testid={`${RateMessage.name}-message`}>
        {getMessage}
      </Styles.Text>
    </Styles.ContainerRateMessage>
  );
}
