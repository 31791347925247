import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  ctiSharedService,
  PhoneResponseType,
  PhoneTypeEnum,
  ScoreEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UsePhoneRankItem {
  phoneContact: string;
  translatedPhoneType: string;
  currentPhone: string;
  score: ScoreEnum;
  isConnected: boolean;
  tagLabel: string;
}
export function usePhoneRankItem(
  phoneListData: PhoneResponseType,
): UsePhoneRankItem {
  const { formatMessage } = useIntl();
  const ctiService = ctiSharedService();
  const { calledNumbers, lastCopiedNumber, isConnected } =
    useSharedUnit(ctiService);

  const {
    phoneNumber: currentPhone,
    phoneContact,
    phoneType,
    score,
  } = phoneListData;

  const formatPhoneType = useCallback(
    (phoneType: string): string => {
      return phoneType
        .split(",")
        .map((type: PhoneTypeEnum) =>
          formatMessage({ id: `PhoneNumbers.PHONE_TYPE.${type}` }),
        )
        .join(", ");
    },
    [formatMessage],
  );

  const getTagLabel = () => {
    if (isConnected) {
      const isCurrentPhoneCalled = calledNumbers.some(
        (calledNumber) => calledNumber.phoneNumber === currentPhone,
      );

      if (isCurrentPhoneCalled) {
        return formatMessage({ id: "PhoneNumbers.CALLED_TAG" });
      }
    } else if (lastCopiedNumber === currentPhone) {
      return formatMessage({ id: "PhoneNumbers.COPIED_TAG" });
    }
    return null;
  };

  const tagLabel = getTagLabel();

  const translatedPhoneType = useMemo(
    () => formatPhoneType(phoneType),
    [phoneType, formatPhoneType],
  );

  return {
    phoneContact,
    translatedPhoneType,
    currentPhone,
    score,
    isConnected,
    tagLabel,
  };
}
