import { MissionType } from "@bees-grow-shared/services";

import Text from "./components/Text";
import { useTask } from "./hooks/useTask";
import * as Styled from "./TaskCard.styles";

interface TaskProps {
  missionKey: string;
  number: number;
  name: string;
  description: string;
  type: MissionType;
}

export default function TaskCard({
  missionKey,
  name,
  description,
  number,
  type,
}: Readonly<TaskProps>) {
  const { titleFormatted, descriptionFormatted } = useTask({
    missionKey,
    title: name,
    description,
    type,
  });

  return (
    <Styled.Container>
      <Text
        text={`${number}. ${titleFormatted}`}
        tooltipText={titleFormatted}
        weight="semibold"
      />
      <Text
        text={descriptionFormatted}
        tooltipText={descriptionFormatted}
        weight="normal"
      />
    </Styled.Container>
  );
}
