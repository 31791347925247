import { useIntl } from "react-intl";

import { PhoneResponseType } from "@bees-grow-shared/services";
import { Pagination } from "@hexa-ui/components";

import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

import { PaginationProps } from "../../hooks/usePhoneRank/usePhoneRank";
import PhoneRankItem from "../phoneRankItem/PhoneRankItem";
import * as Styles from "./PhoneRankList.styles";

interface PhoneRankListProps {
  isDropdownOpen: boolean;
  paginationData: PaginationProps;
}

export default function PhoneRankList({
  paginationData,
  isDropdownOpen,
}: PhoneRankListProps) {
  const MINIMUM_PAGE_SIZE = 5;
  const { formatMessage } = useIntl();
  const { handleOpenModal } = usePhoneManagerModal();

  const {
    currentPage,
    paginatedPhoneListData,
    setCurrentPage,
    pageSize,
    total,
  } = paginationData;

  return (
    <Styles.Container
      isDropdownOpen={isDropdownOpen}
      data-testid="PhoneRankList"
    >
      <Styles.Wrapper>
        {paginatedPhoneListData.map((item: PhoneResponseType, index) => (
          <PhoneRankItem
            phoneListData={item}
            key={`PhoneRankList-${index}`}
            data-testid={`PhoneRankList-item-${index}`}
          />
        ))}
      </Styles.Wrapper>
      {total > MINIMUM_PAGE_SIZE && (
        <Styles.PaginationContainer data-testid="Pagination">
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            total={total}
            showQuantityIndicator={false}
            showPageSizeSelector={false}
          />
        </Styles.PaginationContainer>
      )}
      <Styles.TextButton
        onClick={() => handleOpenModal()}
        data-testid={"PhoneRankList-add-phone-button"}
      >
        + {formatMessage({ id: "PhoneNumbers.BUTTON_ADD_PHONE" })}
      </Styles.TextButton>
    </Styles.Container>
  );
}
