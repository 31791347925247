import { tokens } from "@bees-grow-shared/theme";
import { Badge, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Tag = styled(Badge.Status)<{ "is-called-type": boolean }>`
  font-size: ${tokens.measure["font-size"]["fontsize-1"]};
  border-radius: ${tokens.measure.space["spacing-1"]};
  padding-top: ${tokens.measure.space["spacing-1"]};
  padding-bottom: ${tokens.measure.space["spacing-1"]};
  padding-left: ${tokens.measure.space["spacing-2"]};
  padding-right: ${tokens.measure.space["spacing-2"]};
  border: none;
  background-color: ${(props) =>
    props["is-called-type"] ? "#eee8ff" : "#D7F0FC"};
  height: 100%;
  & > p {
    color: ${(props) => (props["is-called-type"] ? "#705ab3" : "#1B6587")};
    font-size: ${tokens.measure["font-size"]["fontsize-1"]};
    line-height: ${tokens.measure["line-height"]["lineheight-4"]};
    font-weight: ${tokens.fonts["font-weight"].normal};
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const ContainerRate = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const NumberInfo = styled(Paragraph)<{ "is-inactive"?: string }>`
  margin: 0.25rem 0;
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  color: ${tokens.color.foundation.txt.default};
  &[${"is-inactive"}="INACTIVE"] {
    color: ${tokens.color.primitive.transparent.black["black-32"]};
  }
`;

export const Text = styled(Paragraph)<{
  weak?: boolean;
  "is-inactive"?: string;
}>`
  color: ${(props) =>
    props.weak
      ? tokens.color.primitive.transparent.black["black-56"]
      : tokens.color.foundation.txt.default};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};

  &[${"is-inactive"}="INACTIVE"] {
    color: ${tokens.color.primitive.transparent.black["black-32"]};
  }
`;
