import { Table } from "@hexa-ui/components";

import { useTablePreview } from "./hooks/useTablePreview";
import * as Styled from "./TablePreview.styles";

export default function TablePreview() {
  const {
    columns,
    parsedLastOrders,
    handleOnRowClick,
    handleSort,
    savedSortDirection,
    showLastOrderModal,
  } = useTablePreview();

  if (showLastOrderModal) {
    return <></>;
  }

  return (
    <Styled.TableContainer>
      <Table
        pagination={false}
        columns={columns}
        data={parsedLastOrders}
        onRow={handleOnRowClick}
        onSort={handleSort}
        initialSortBy={[
          {
            id: "placed",
            desc: savedSortDirection === "DESC",
            asc: savedSortDirection === "ASC",
          },
        ]}
      />
    </Styled.TableContainer>
  );
}
