import { useCallback } from "react";
import { useIntl } from "react-intl";

import { ClassificationEnum } from "@bees-grow-shared/services";

function getMessageId(
  classificationType: ClassificationEnum,
  messageType: "Error" | "Success",
): string {
  const messageMap: { [key: string]: string } = {
    [ClassificationEnum.ACTIVATE]: `PhoneNumbers.ToastMessage.${messageType}.ACTIVATION`,
    [ClassificationEnum.INACTIVATE]: `PhoneNumbers.ToastMessage.${messageType}.INACTIVATION`,
    [ClassificationEnum.NO_ANSWER]: `PhoneNumbers.ToastMessage.${messageType}.CLASSIFICATION`,
    [ClassificationEnum.GOOD]: `PhoneNumbers.ToastMessage.${messageType}.CLASSIFICATION`,
  };

  return (
    messageMap[classificationType] ||
    `PhoneNumbers.ToastMessage.${messageType}.CLASSIFICATION`
  );
}

export function usePhoneMessages(phoneNumber: string) {
  const { formatMessage } = useIntl();

  const getFormattedMessage = useCallback(
    (
      classificationType: ClassificationEnum,
      phoneNumber: string,
      messageType: "Error" | "Success",
    ): string => {
      const messageId = getMessageId(classificationType, messageType);

      if (
        messageId.includes("ACTIVATION") ||
        messageId.includes("INACTIVATION")
      ) {
        const template = formatMessage({ id: messageId });
        return template.replace(/\$\{\}/, phoneNumber);
      }

      return formatMessage({ id: messageId });
    },
    [formatMessage],
  );

  const getErrorMessage = useCallback(
    (classificationType: ClassificationEnum) => {
      return getFormattedMessage(classificationType, phoneNumber, "Error");
    },
    [getFormattedMessage, phoneNumber],
  );

  const getSuccessMessage = useCallback(
    (classificationType: ClassificationEnum) => {
      return getFormattedMessage(classificationType, phoneNumber, "Success");
    },
    [getFormattedMessage, phoneNumber],
  );

  return { getErrorMessage, getSuccessMessage };
}
