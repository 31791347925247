import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Message = styled.div`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  line-height: ${tokens.measure["font-size"]["fontsize-5"]};
`;

export const LoadingTitle = styled(SkeletonLoader)`
  width: 5.375rem;
  height: 1.5rem;
`;

export const LoadingMessage = styled(SkeletonLoader)`
  width: 12.5rem;
  height: 1.3rem;
`;
