import React, { createContext, useCallback, useContext, useState } from "react";

import { PhoneResponseType } from "@bees-grow-shared/services";

interface PhoneManagerModalContextProps {
  isModalOpen: boolean;
  modalData?: PhoneResponseType;
  handleOpenModal: () => void;
  handleEditModal: (phone: PhoneResponseType) => void;
  handleCloseModal: () => void;
}

const PhoneManagerModalContext = createContext<
  PhoneManagerModalContextProps | undefined
>(undefined);

export const PhoneManagerModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<PhoneResponseType | null>(null);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleEditModal = useCallback((phone: PhoneResponseType) => {
    setModalData(phone);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalData(null);
    setIsModalOpen(false);
  }, []);

  return (
    <PhoneManagerModalContext.Provider
      value={{
        isModalOpen,
        handleOpenModal,
        modalData,
        handleCloseModal,
        handleEditModal,
      }}
    >
      {children}
    </PhoneManagerModalContext.Provider>
  );
};

export const usePhoneManagerModal = (): PhoneManagerModalContextProps => {
  const context = useContext(PhoneManagerModalContext);
  if (!context) {
    throw new Error(
      "usePhoneManagerModal must be used within a PhoneManagerModalProvider",
    );
  }
  return context;
};
