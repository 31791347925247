import { useMemo } from "react";

import {
  rewardsSharedService,
  useFormattedDate,
  useSharedUnit,
} from "@bees-grow-shared/services";

export interface UseEnrolledSinceProps {
  formattedDate: string;
  pocHasClubB: boolean;
  isLoading: boolean;
}

export function useEnrolledSince(): UseEnrolledSinceProps {
  const rewardsService = rewardsSharedService();
  const { rewards } = useSharedUnit(rewardsService);
  const { dateRegistered, status } = rewards || {};
  const { formatDate } = useFormattedDate();

  const formattedDate = useMemo(() => {
    return formatDate(dateRegistered);
  }, [formatDate, dateRegistered]);

  const pocHasClubB = useMemo(() => {
    return !!dateRegistered;
  }, [dateRegistered]);

  return {
    formattedDate,
    pocHasClubB,
    isLoading: status?.isLoading,
  };
}
