import { useFormattedDate } from "@bees-grow-shared/services";

import * as Styled from "./PlacedColumn.styles";

interface PlacedColumnProps {
  placed: string;
}

export function PlacedColumn({ placed }: Readonly<PlacedColumnProps>) {
  const { formatDate } = useFormattedDate();

  const formattedDate = formatDate(placed);

  return (
    <Styled.Container>
      <Styled.Text data-testid="placed-formatted">{formattedDate}</Styled.Text>
    </Styled.Container>
  );
}
