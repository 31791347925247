import { useIntl } from "react-intl";

import { PhoneResponseType, ScoreEnum } from "@bees-grow-shared/services";

import ActionButton from "./components/ActionButton";
import { useActionIcons } from "./hooks/useActionIcons/useActionIcons";
import { useVoteIcons } from "./hooks/useVoteIcons/useVoteIcons";
import * as Styles from "./PhoneActions.styles";

interface PhoneActionsProps {
  isRateType?: boolean;
  phoneListData: PhoneResponseType;
}

export default function PhoneActions({
  isRateType = false,
  phoneListData,
}: Readonly<PhoneActionsProps>) {
  const { formatMessage } = useIntl();

  const { voteIcons, handleActivatePhone } = useVoteIcons(phoneListData);
  const { actionIcons } = useActionIcons(phoneListData);

  const { score } = phoneListData;

  if (isRateType) {
    if (score === ScoreEnum.INACTIVE) {
      return (
        <Styles.Button
          variant="secondary"
          size="small"
          data-testid="PhoneActions-rate-inactive"
          onClick={handleActivatePhone}
        >
          {formatMessage({ id: "PhoneNumbers.BUTTON_ACTIVATE" })}
        </Styles.Button>
      );
    }
    return (
      <Styles.ActionList data-testid="PhoneActions-rate">
        {voteIcons.map((item) => (
          <ActionButton
            actionIcon={item.icon}
            onClick={item.onClick}
            key={item.icon.key}
          />
        ))}
      </Styles.ActionList>
    );
  }

  if (score === ScoreEnum.INACTIVE) {
    return null;
  }

  return (
    <Styles.ActionList data-testid="PhoneActions">
      {actionIcons.map((item) => (
        <ActionButton
          onClick={item.onClick}
          actionIcon={item.icon}
          key={item.icon.key}
        />
      ))}
    </Styles.ActionList>
  );
}
