import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  display: grid;
  gap: 0.3125rem;
  grid-template-columns: 0fr 1fr;
  align-items: center;
`;

export const TextContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentContainer = styled.div`
  min-width: 4.0625rem;
`;
