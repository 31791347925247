import { useCurrencyFormatter } from "@bees-grow-shared/services";

import * as Styled from "./TotalColumn.styles";

interface TotalColumnProps {
  total: number;
}

export function TotalColumn({ total }: Readonly<TotalColumnProps>) {
  const formattedCurrency = useCurrencyFormatter(total, {
    minimumFractionDigits: 2,
  });

  return (
    <Styled.Container data-testid="total-text">
      {formattedCurrency}
    </Styled.Container>
  );
}
