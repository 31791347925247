import { tokens } from "@bees-grow-shared/theme";
import {
  IconButton as IconButtonHexa,
  Input as InputHexa,
} from "@hexa-ui/components";
import styled from "styled-components";

export const IconButton = styled(IconButtonHexa)`
  background-color: ${tokens.color.component.bg.button.tertiary.default};
  border: none;

  &:hover {
    box-shadow: none;
    background-color: ${tokens.color.flavor.brand.grow["grow-70"]};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};

  & > div {
    width: auto !important;
    display: flex;
    flex-direction: column;
  }

  & label[class*="hasError-true"] {
    color: ${tokens.color.foundation.txt.error};
  }

  & label[for] {
    color: ${tokens.color.foundation.txt.default};
    line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  }

  & > div > div:nth-child(1) {
    padding: 0;
  }

  & > div > div:nth-child(2) {
    color: ${tokens.color.foundation.txt.secondary};
  }

  & > div > div:nth-child(4)[class*="hasError-true"] {
    margin-bottom: ${tokens.measure.space["spacing-1"]};
    outline-color: ${tokens.color.foundation.txt.error};
  }

  & > div > div:nth-child(3):has(svg) {
    font-size: ${tokens.measure["font-size"]["fontsize-1"]};
    color: ${tokens.color.foundation.txt.error};
    order: 1;
    padding: 0;
  }
`;

export const Input = styled(InputHexa)`
  height: 2.5rem;
  padding-top: ${tokens.measure.space["spacing-2"]};
  padding-bottom: ${tokens.measure.space["spacing-2"]};
  padding-left: ${tokens.measure.space["spacing-4"]};
  padding-right: ${tokens.measure.space["spacing-4"]};
`;

export const ActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row !important;
  gap: ${tokens.measure.space["spacing-4"]};
  margin-top: ${tokens.measure.space["spacing-4"]};
`;
