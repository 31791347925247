import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  accountSharedService,
  missionSharedService,
  MissionType,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { MISSIONS_PATH } from "@/config/constants";
import segmentAnalytics from "@/config/typewriter";

interface Task {
  missionKey: string;
  name: string;
  description: string;
  type: MissionType;
}

interface UseTasksProps {
  tasks: Task[];
  isLoading: boolean;
  retry: () => void;
  error: boolean;
  errorMessage: string;
  handleGoToTasksClick: () => void;
}

export const useTasks = (): UseTasksProps => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const accountService = accountSharedService();
  const missionService = missionSharedService();
  const defaultData = useDefaultAnalyticsData();

  const {
    accountId,
    vendorId,
    isLoading: accountServiceIsLoading,
  } = useSharedUnit(accountService);
  const {
    missions,
    prioritizedMissions: {
      isLoading: missionServiceIsLoading,
      error: missionServiceError,
    },
  } = useSharedUnit(missionService);

  const getPrioritizedMissions = useCallback(() => {
    if (accountId && vendorId) {
      missionService.setPrioritizedMissions({ accountId, vendorId });
    }
  }, [accountId, missionService, vendorId]);

  useEffect(getPrioritizedMissions, [getPrioritizedMissions]);

  const tasks = useMemo(() => {
    return missions.map(({ name, description, type, missionKey }) => {
      return {
        missionKey,
        name,
        description,
        type,
      } as Task;
    });
  }, [missions]);

  useEffect(() => {
    if (missionServiceError) {
      segmentAnalytics.errorEvent({
        error_message: formatMessage({ id: "Tasks.UNABLE_TO_LOAD" }),
        error_type: "Request failed",
        screen_name: "Call tasks",
      });
    }
  }, [formatMessage, missionServiceError]);

  const handleGoToTasksClick = () => {
    segmentAnalytics.buttonClicked({
      button_label: formatMessage({ id: "Tasks.GO_TO_TASKS" }),
      button_name: "tasks link",
      calling_method: "CLICK",
      url: MISSIONS_PATH,
      screen_name: "Call tasks",
      ...defaultData,
    });
    navigate(MISSIONS_PATH);
  };

  return {
    isLoading: accountServiceIsLoading || missionServiceIsLoading,
    tasks,
    handleGoToTasksClick,
    retry: getPrioritizedMissions,
    error: missionServiceError,
    errorMessage: formatMessage({
      id: "Tasks.UNABLE_TO_LOAD",
    }),
  };
};
