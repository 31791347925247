import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  align-items: center;
  min-width: 89px;
`;

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
