import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";
import { Button } from "@hexa-ui/components";

import { useNotes } from "./hooks/useNotes";
import * as Styled from "./Notes.styles";

export default function Notes() {
  const { formatMessage } = useIntl();
  const {
    customerNotes,
    handleChangeCustomerNotes,
    handleUpsertNote,
    isLoadingGetNote,
    handleGetNote,
    getNoteError,
  } = useNotes();

  if (isLoadingGetNote) return <Loading />;
  if (getNoteError) return <ErrorBoundary handleGetNote={handleGetNote} />;

  return (
    <Styled.Container>
      <Styled.Title size="H4" data-testid="notes-title">
        {formatMessage({ id: "Notes.TITLE" })}
      </Styled.Title>
      <Styled.TextArea
        width={"100%"}
        value={customerNotes?.note}
        onChange={handleChangeCustomerNotes}
        placeholder={formatMessage({ id: "Notes.PLACEHOLDER" })}
        required={true}
      />
      <Styled.ButtonContainer>
        <Button variant="secondary" size="small" onClick={handleUpsertNote}>
          {formatMessage({ id: "Notes.ACTION" })}
        </Button>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
}

export const Loading = () => {
  return (
    <>
      <Styled.TitleLoader data-testid="notes-skeleton" />
      <Styled.TextAreaLoader />
    </>
  );
};

function ErrorBoundary({ handleGetNote }: { handleGetNote: () => void }) {
  const { formatMessage } = useIntl();
  return (
    <Styled.Container>
      <Styled.Title size="H4" data-testid="notes-title">
        {formatMessage({ id: "Notes.TITLE" })}
      </Styled.Title>
      <RetryAlert
        data-testid="notes-error"
        message={formatMessage({ id: "Notes.Error.MESSAGE" })}
        actionLabel={formatMessage({ id: "Notes.Error.ACTION" })}
        action={handleGetNote}
      />
    </Styled.Container>
  );
}
