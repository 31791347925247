import { tokens } from "@bees-grow-shared/theme";
import { IconButton as IconButtonHexa } from "@hexa-ui/components";
import styled from "styled-components";

export const IconButton = styled(IconButtonHexa)<{
  hovervariant?: string;
}>`
  ${({ hovervariant }) =>
    hovervariant === "click_call" &&
    `
      background-color: ${tokens.color.component.bg.button.tertiary.default};
      border: none;
     
      &:hover {
        box-shadow: none;
        background-color: ${tokens.color.flavor.brand.grow["grow-70"]};
      }
  `}

  ${({ hovervariant }) =>
    (hovervariant === "copy_number" || hovervariant === "edit_number") &&
    `
      border: none;
      background-color: transparent;
      
      &:hover {
        box-shadow: none;
        background-color: ${tokens.color.component.bg.button.tertiary.default};
      }
  `}

  ${({ hovervariant }) =>
    (hovervariant === "successful_call" ||
      hovervariant === "no_answer" ||
      hovervariant === "inactive") &&
    `
      background-color: ${tokens.color.component.bg.button.secondary.default};
   
      &:hover {
        box-shadow: none;
        background-color: ${tokens.color.flavor.brand.grow["grow-70"]};
      }
  `}
`;
