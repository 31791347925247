import { useIntl } from "react-intl";

import { X } from "@hexa-ui/icons";

import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

import * as Styles from "./PhoneManagerModal.styles";
import PhoneRankForm from "./phoneRankForm/PhoneRankForm";

export default function PhoneManagerModal() {
  const { formatMessage } = useIntl();
  const { isModalOpen, handleCloseModal, modalData } = usePhoneManagerModal();

  return (
    <Styles.Modal
      onEscapeKeyDown={handleCloseModal}
      actions={null}
      open={isModalOpen}
    >
      <Styles.ModalContent data-testid={"PhoneManagerModal"}>
        <Styles.Header>
          <Styles.HeadingModal
            aria-level={3}
            data-testid={"PhoneManagerModal-title"}
          >
            {modalData?.phoneNumber
              ? formatMessage({
                  id: "PhoneNumbers.AddEditPhoneModal.EDIT_TITLE",
                })
              : formatMessage({
                  id: "PhoneNumbers.AddEditPhoneModal.ADD_TITLE",
                })}
          </Styles.HeadingModal>
          <Styles.IconButton
            variant="tertiary"
            icon={() => <X size="tiny" key="close" />}
            size="small"
            data-testid={`PhoneManagerModal-button-close`}
            onClick={handleCloseModal}
          ></Styles.IconButton>
        </Styles.Header>
        <PhoneRankForm />
      </Styles.ModalContent>
    </Styles.Modal>
  );
}
