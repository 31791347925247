/* eslint-disable no-console */
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { ClipboardButton } from "@bees-grow-shared/components";
import {
  agentCallSharedService,
  ctiSharedService,
  phoneRankSharedService,
  PhoneResponseType,
  PhoneTypeEnum,
  PocCallOrigin,
  RT4PublishCommandEnum,
  useCopyToClipboard,
  useDefaultAnalyticsData,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { Edit2, Phone } from "@hexa-ui/icons";

import { GROW_PORTAL_COPY_PHONE_AUTOMATICALLY } from "@/config/featureToggles";
import segmentAnalytics from "@/config/typewriter";
import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

interface PhoneIconsProps {
  icon: React.ReactElement;
  onClick?: () => void;
}

export function useActionIcons(phoneListData: PhoneResponseType): {
  actionIcons: Array<PhoneIconsProps>;
} {
  const { formatMessage } = useIntl();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const phoneRankService = phoneRankSharedService();
  const defaultData = useDefaultAnalyticsData();
  const screenName = "account";

  const { pocCallOrigin, startCallDate, ctiEnabled } =
    useSharedUnit(agentCallService);
  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);
  const { phones } = useSharedUnit(phoneRankService);

  const { handleEditModal } = usePhoneManagerModal();
  const [copy] = useCopyToClipboard();

  const { isEnabled: isCopyPhoneAutomaticallyEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_COPY_PHONE_AUTOMATICALLY);

  const { phoneNumber, phoneType, score } = phoneListData;

  const shouldShowCallIcon = useCallback(() => {
    return !(
      pocCallOrigin === PocCallOrigin.AUTO_DIALER ||
      (pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT && !ctiEnabled) ||
      (ctiEnabled && !isCTIConnected) ||
      !ctiEnabled
    );
  }, [ctiEnabled, isCTIConnected, pocCallOrigin]);

  const handleCopiedNumber = useCallback(() => {
    segmentAnalytics.phoneCalled({
      phone_number: phoneNumber,
      screen_name: screenName,
      phone_score: score,
      phone_source: phoneType,
      call_method: "COPY",
      ...defaultData,
    });
    ctiService.setCopiedNumbers(phoneNumber);
  }, [ctiService, defaultData, phoneNumber, phoneType, score]);

  const handleSendMakeCallEvent = useCallback(() => {
    segmentAnalytics.phoneCalled({
      phone_number: phoneNumber,
      screen_name: screenName,
      phone_score: score,
      phone_source: phoneType,
      call_method: "CLICK_TO_CALL",
      ...defaultData,
    });
    ctiService.send({
      command: RT4PublishCommandEnum.MAKE_CALL,
      payload: {
        phoneNumber: phoneNumber,
      },
    });
  }, [ctiService, defaultData, phoneNumber, phoneType, score]);

  const actionIcons = useMemo(() => {
    const icons: Array<PhoneIconsProps> = [];

    if (phoneType === PhoneTypeEnum.INPUTTED_PHONE) {
      icons.push({
        icon: <Edit2 size="tiny" key="edit_number" />,
        onClick: () => handleEditModal(phoneListData),
      });
    }

    if (shouldShowCallIcon()) {
      icons.unshift({
        icon: <Phone size="tiny" key="click_call" />,
        onClick: handleSendMakeCallEvent,
      });
    } else {
      icons.unshift({
        icon: (
          <ClipboardButton
            key="copy_number"
            iconSize="medium"
            textToCopy={phoneNumber}
            testId="copy_number"
            tooltipCopiedText={formatMessage({ id: "PhoneNumbers.COPIED_TAG" })}
            tooltipCopyText={formatMessage({ id: "PhoneNumbers.COPY_TOOLTIP" })}
            onClick={handleCopiedNumber}
          />
        ),
      });
    }

    return icons;
  }, [
    phoneType,
    shouldShowCallIcon,
    handleEditModal,
    phoneListData,
    handleSendMakeCallEvent,
    phoneNumber,
    formatMessage,
    handleCopiedNumber,
  ]);

  const handleCopyClick = useCallback(
    (bestPhoneToCall: string) => {
      copy(bestPhoneToCall);
      ctiService.setCopiedNumbers(phoneNumber);
    },
    [copy, ctiService, phoneNumber],
  );

  useEffect(() => {
    if (
      isCopyPhoneAutomaticallyEnabled &&
      !ctiEnabled &&
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER &&
      pocCallOrigin !== PocCallOrigin.SEARCH_BY_POC &&
      phoneNumber === phones[0].phoneNumber
    ) {
      handleCopyClick(phones[0].phoneNumber);
    }
  }, [
    actionIcons,
    ctiService,
    handleCopyClick,
    ctiEnabled,
    isCopyPhoneAutomaticallyEnabled,
    phoneNumber,
    phones,
    pocCallOrigin,
    startCallDate,
  ]);

  return { actionIcons };
}
