import { tokens } from "@bees-grow-shared/theme";
import { TextButton as TextButtonHexa } from "@hexa-ui/components";
import styled from "styled-components";

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  & > li:nth-child(even) {
    background-color: ${tokens.color.foundation.bg["primary-weak"]};
  }

  & > li:nth-child(odd) {
    background-color: transparent;
  }
`;

export const PaginationContainer = styled.div`
  margin: 0.5rem 0.625rem;
  width: 93.86503%;

  & > div {
    width: 100%;

    & > nav {
      width: 100%;

      & > ul {
        width: 100%;

        & > li {
          width: 100%;
        }
      }
    }
  }
`;

export const Container = styled.div<{ isDropdownOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: ${(props) => (props.isDropdownOpen ? "56.25rem" : "0")};
  transition: max-height 500ms ease-in-out;
`;

export const TextButton = styled(TextButtonHexa)`
  align-self: center;
  margin: 1rem 0;
  color: ${tokens.color.component.txt.button.text.default};
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
`;
