import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";

import PhoneManagerModal from "./components/phoneManagerModal/PhoneManagerModal";
import PhoneRankHeader from "./components/phoneRankHeader/PhoneRankHeader";
import PhoneRankList from "./components/phoneRankList/PhoneRankList";
import { usePhoneRank } from "./hooks/usePhoneRank/usePhoneRank";
import * as Styles from "./PhoneNumbers.styles";

export default function PhoneNumbers() {
  const {
    isDropdownOpen,
    toggleDropdown,
    isLoading,
    containerRef,
    isPhoneRankError,
    retryPhoneRankFetch,
    paginationData,
  } = usePhoneRank();

  if (isLoading) return <Loading />;

  return (
    <Styles.Container ref={containerRef} data-testid="PhoneNumbers-container">
      <PhoneRankHeader
        isDropdownOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        hasError={isPhoneRankError}
      />
      {isPhoneRankError ? (
        <ErrorBoundary retryAction={retryPhoneRankFetch} />
      ) : (
        <PhoneRankList
          isDropdownOpen={isDropdownOpen}
          paginationData={paginationData}
        />
      )}
      <PhoneManagerModal />
    </Styles.Container>
  );
}

export function Loading() {
  return (
    <>
      <Styles.TitleLoader data-testid="LoadingSkeleton-title" />
      {[...Array(5)].map((_, index) => (
        <PhoneSkeleton key={index} />
      ))}
    </>
  );
}

export function PhoneSkeleton() {
  return (
    <Styles.PhoneContainer>
      <Styles.PhoneColumn>
        <Styles.PhoneColumnTitle />
        <Styles.PhoneColumnSubtitle />
        <Styles.PhoneColumnContent />
      </Styles.PhoneColumn>
      <Styles.PhoneColumn>
        <Styles.PhoneColumnTitle />
        <Styles.PhoneRow>
          <Styles.PhoneIcon />
          <Styles.PhoneIcon />
          <Styles.PhoneIcon />
        </Styles.PhoneRow>
      </Styles.PhoneColumn>
    </Styles.PhoneContainer>
  );
}

function ErrorBoundary({ retryAction }: { retryAction: () => void }) {
  const { formatMessage } = useIntl();
  return (
    <Styles.ErrorContainer>
      <RetryAlert
        message={formatMessage({ id: "PhoneNumbers.Error.MESSAGE" })}
        actionLabel={formatMessage({ id: "PhoneNumbers.Error.ACTION" })}
        action={retryAction}
      />
    </Styles.ErrorContainer>
  );
}
