import { useCallback, useMemo } from "react";

import { useToast } from "@bees-grow-shared/business-components";
import {
  accountSharedService,
  agentCallSharedService,
  ClassificationEnum,
  ctiSharedService,
  phoneRankSharedService,
  PhoneResponseType,
  PocCallOrigin,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { PhoneOff, ThumbsDown, ThumbsUp } from "@hexa-ui/icons";

import segmentAnalytics from "@/config/typewriter";
import { usePhoneActionModal } from "@/contexts/PhoneActionModalContext/PhoneActionModalContext";

import { usePhoneMessages } from "../usePhoneMessages/usePhoneMessages";

interface PhoneIconsProps {
  icon: React.ReactElement;
  onClick?: () => void;
}

interface VoteIconsProps {
  voteIcons: Array<PhoneIconsProps>;
  handleActivatePhone: () => void;
}

export function useVoteIcons(phoneListData: PhoneResponseType): VoteIconsProps {
  const toast = useToast();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();

  const { accountId, vendorId } = useSharedUnit(accountService);
  const { pocCallOrigin } = useSharedUnit(agentCallService);
  const { isConnected: isCTIConnected, calledNumbers } =
    useSharedUnit(ctiService);

  const ctiEnabled = true;

  const { handleOpenModal } = usePhoneActionModal();
  const defaultData = useDefaultAnalyticsData();

  const {
    phoneNumber: currentPhone,
    score: currentScore,
    phoneType,
  } = phoneListData;

  const { getErrorMessage, getSuccessMessage } = usePhoneMessages(currentPhone);

  const handleSendAnalytics = useCallback(
    (classification) => {
      if (
        classification === ClassificationEnum.INACTIVATE ||
        classification === ClassificationEnum.ACTIVATE
      ) {
        segmentAnalytics.phoneStatusChanged({
          current_status: classification,
          phone_number: currentPhone,
          phone_source: phoneType,
          screen_name: "account",
          ...defaultData,
        });
        return;
      }

      segmentAnalytics.phoneClassified({
        phone_classification: currentScore,
        phone_number: currentPhone,
        phone_source: phoneType,
        new_score: classification,
        screen_name: "account",
        ...defaultData,
      });
    },
    [currentPhone, currentScore, defaultData, phoneType],
  );

  const voteActions = useCallback(
    (classification) => {
      const { phoneContact, phoneType } = phoneListData;
      handleSendAnalytics(classification);
      phoneRankService
        .updatePhoneRank({
          params: {
            phoneNumber: currentPhone,
            accountId,
            vendorId,
          },
          phone: {
            classification,
            contactName: phoneContact,
            phoneNumber: currentPhone,
            phoneType,
          },
        })
        .then(() => {
          const message = getSuccessMessage(classification);
          toast({
            showCloseButton: true,
            type: "success",
            message,
          });
        })
        .catch(() => {
          const message = getErrorMessage(classification);
          toast({
            showCloseButton: true,
            type: "error",
            message,
          });
        });
    },
    [
      accountId,
      currentPhone,
      getErrorMessage,
      getSuccessMessage,
      handleSendAnalytics,
      phoneListData,
      phoneRankService,
      toast,
      vendorId,
    ],
  );

  const handleActivatePhone = useCallback(() => {
    handleOpenModal({
      phone: currentPhone,
      type: ClassificationEnum.ACTIVATE,
      onClick: () => voteActions(ClassificationEnum.ACTIVATE),
    });
  }, [currentPhone, handleOpenModal, voteActions]);

  const handleInactivatePhone = useCallback(() => {
    voteActions(ClassificationEnum.INACTIVATE);
  }, [voteActions]);

  const voteIcons = useMemo(() => {
    const icons: Array<PhoneIconsProps> = [];
    const isCurrentPhoneCalled = calledNumbers.find(
      (calledNumber) => calledNumber.phoneNumber === currentPhone,
    );

    const addInactiveIcon = () => {
      icons.unshift({
        icon: <PhoneOff size="tiny" key="inactive" />,
        onClick: () =>
          handleOpenModal({
            phone: phoneListData.phoneNumber,
            type: ClassificationEnum.INACTIVATE,
            onClick: () => handleInactivatePhone(),
          }),
      });
    };

    const addCommonIcons = () => {
      icons.push(
        {
          icon: <ThumbsDown size="tiny" key="no_answer" />,
          onClick: () => voteActions(ClassificationEnum.NO_ANSWER),
        },
        {
          icon: <ThumbsUp size="tiny" key="successful_call" />,
          onClick: () => voteActions(ClassificationEnum.GOOD),
        },
      );
    };

    if (
      ctiEnabled &&
      isCTIConnected &&
      isCurrentPhoneCalled &&
      !isCurrentPhoneCalled.isCallFailed &&
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      addInactiveIcon();
      addCommonIcons();
    }

    if (
      ((ctiEnabled && !isCTIConnected) || !ctiEnabled) &&
      pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      addInactiveIcon();
      addCommonIcons();
    }

    if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
      addInactiveIcon();
    }

    return icons;
  }, [
    calledNumbers,
    ctiEnabled,
    currentPhone,
    handleInactivatePhone,
    handleOpenModal,
    isCTIConnected,
    phoneListData.phoneNumber,
    pocCallOrigin,
    voteActions,
  ]);

  return {
    handleActivatePhone,
    voteIcons,
  };
}
