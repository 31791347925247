import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  lastOrdersSharedService,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import segmentAnalytics from "@/config/typewriter";

export function useLastOrdersPreview() {
  const lastOrdersService = lastOrdersSharedService();
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const defaultData = useDefaultAnalyticsData();
  const { formatMessage } = useIntl();

  const {
    lastOrders,
    done: isLastOrdersDone,
    error: isLastOrdersError,
    isLoading: isLastOrderLoading,
  } = useSharedUnit(lastOrdersService);

  const getLastOrders = useCallback(() => {
    if (accountId && vendorId) {
      lastOrdersService.getLastOrders({
        accountId,
        vendorId,
      });
    }
  }, [accountId, lastOrdersService, vendorId]);

  useEffect(() => {
    if (!isLastOrdersDone && !isLastOrderLoading) {
      getLastOrders();
    }
  }, [getLastOrders, isLastOrderLoading, isLastOrdersDone]);

  const isLastOrdersEmpty =
    isLastOrdersDone && !isLastOrdersError && !lastOrders.length;

  useEffect(() => {
    if (isLastOrdersError) {
      segmentAnalytics.errorEvent({
        error_message: formatMessage({ id: "LastOrders.Error.MESSAGE" }),
        error_type: "error",
        screen_name: "account",
        ...defaultData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastOrdersError]);

  useEffect(() => {
    if (isLastOrdersEmpty) {
      segmentAnalytics.errorEvent({
        error_message: formatMessage({ id: "LastOrders.EmptyState.MESSAGE" }),
        error_type: "info",
        screen_name: "account",
        ...defaultData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastOrdersEmpty]);

  return {
    isLastOrderLoading,
    isLastOrdersEmpty,
    isLastOrdersError,
    getLastOrders,
  };
}
