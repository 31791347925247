import { tokens } from "@bees-grow-shared/theme";
import { Heading, TextLink } from "@hexa-ui/components";
import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 7.25rem);
  }
  height: 3rem;
`;

export const Title = styled(Heading)`
  margin-bottom: 1rem;
`;

export const CollapsibleButton = styled(TextLink)`
  font-weight: ${tokens.fonts["font-weight"].medium};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  white-space: nowrap;
  display: flex;
  color: ${tokens.color.component.icon.dropdown.default};

  &:hover {
    text-decoration: none;
    color: ${tokens.color.component.icon.dropdown.hover};
  }
`;
