import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const ContainerRateMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.measure.space["spacing-1"]};
  margin: ${tokens.measure.space["spacing-1"]} 0;
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
`;

export const Text = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.default};
  line-height: ${tokens.measure["line-height"]["lineheight-3"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
`;
