import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  agentCallSharedService,
  phoneRankSharedService,
  PhoneResponseType,
  PocCallOrigin,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import segmentAnalytics from "@/config/typewriter";

const PAGE_SIZE = 5;

export interface PaginationProps {
  currentPage: number;
  paginatedPhoneListData: Array<PhoneResponseType>;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  total: number;
}

interface UsePhoneRankProps {
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  isLoading: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  isPhoneRankError: boolean;
  retryPhoneRankFetch: () => void;
  paginationData: PaginationProps;
}

export function usePhoneRank(): UsePhoneRankProps {
  const agentCallService = agentCallSharedService();
  const { pocCallOrigin } = useSharedUnit(agentCallService);
  const isAutoDialer = pocCallOrigin === PocCallOrigin.AUTO_DIALER;

  const [isDropdownOpen, setDropdownOpen] = useState(!isAutoDialer);
  const [currentPage, setCurrentPage] = useState(1);
  const { formatMessage } = useIntl();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const accountService = accountSharedService();
  const phoneRankService = phoneRankSharedService();
  const defaultData = useDefaultAnalyticsData();

  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    phones,
    isLoading,
    error: isPhoneRankError,
  } = useSharedUnit(phoneRankService);

  const retryPhoneRankFetch = useCallback(() => {
    if (accountId && vendorId) {
      phoneRankService.getPhoneRank({ accountId, vendorId });
    }
  }, [accountId, phoneRankService, vendorId]);

  useEffect(() => {
    retryPhoneRankFetch();
  }, [retryPhoneRankFetch]);

  const paginatedPhoneListData = useMemo(() => {
    if (phones && phones.length) {
      const startIndex = (currentPage - 1) * PAGE_SIZE;
      return phones.slice(startIndex, startIndex + PAGE_SIZE);
    }

    return [];
  }, [phones, currentPage]);

  const toggleDropdown = useCallback(() => {
    const button_name = isDropdownOpen
      ? "phone_ranking_collapse"
      : "phone_ranking_expand";
    segmentAnalytics.buttonClicked({
      button_label: formatMessage({ id: "PhoneNumbers.TITLE" }),
      button_name,
      screen_name: "Phone rank",
      url: null,
      calling_method: null,
      ...defaultData,
    });
    setDropdownOpen((prev) => !prev);
  }, [defaultData, formatMessage, isDropdownOpen]);

  return {
    isDropdownOpen,
    toggleDropdown,
    isLoading,
    containerRef,
    isPhoneRankError,
    retryPhoneRankFetch,
    paginationData: {
      paginatedPhoneListData,
      currentPage,
      setCurrentPage,
      pageSize: PAGE_SIZE,
      total: phones ? phones.length : 0,
    },
  };
}
