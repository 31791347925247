import { lazy, Suspense } from "react";

import { Divider } from "@hexa-ui/components";

import { EnvProvider } from "@/components/envProvider/EnvProvider";
import { EnvConfig } from "@/components/envProvider/EnvProvider.types";

import * as Styled from "./App.styles";
import { Loading as ClubBSkeleton } from "./components/clubB/enrolledSince/EnrolledSince";
import { Loading as LastOrdersPreviewSkeleton } from "./components/lastOrdersPreview/LastOrdersPreview";
import { Loading as NotesSkeleton } from "./components/notes/Notes";
import { Loading as PhoneSkeleton } from "./components/phoneNumbers/PhoneNumbers";
import { Loading as TasksSkeleton } from "./components/tasks/Tasks";
import {
  AccountContainer,
  AsideColumn,
  CardContainer,
  ColumnDivider,
  MainColumn,
} from "./pages/account/AccountPage.styles";

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);

const RouterWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.RouterWrapper,
  })),
);

const ToastProvider = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.ToastProvider,
  })),
);

const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = lazy(() =>
  import("@/i18n").then((module) => ({ default: module.IntlProvider })),
);

const PhoneActionModalProvider = lazy(() =>
  import("@/contexts/PhoneActionModalContext/PhoneActionModalContext").then(
    (module) => ({ default: module.PhoneActionModalProvider }),
  ),
);

const PhoneManagerModalProvider = lazy(() =>
  import("@/contexts/PhoneManagerModalContext/PhoneManagerModalContext").then(
    (module) => ({ default: module.PhoneManagerModalProvider }),
  ),
);

const AccountPage = lazy(() => import("@/pages/account/AccountPage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<LoadingBehavior />}>
        <ThemeProvider>
          <UserConfigWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <RouterWrapper>
                    <ToastProvider>
                      <PhoneActionModalProvider>
                        <PhoneManagerModalProvider>
                          <AccountPage />
                        </PhoneManagerModalProvider>
                      </PhoneActionModalProvider>
                    </ToastProvider>
                  </RouterWrapper>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}

function LoadingBehavior() {
  return (
    <AccountContainer>
      <Styled.TitleSkeleton />
      <CardContainer elevated="small" border="medium">
        <AsideColumn>
          <PhoneSkeleton />
          <Divider decorative />
          <NotesSkeleton />
        </AsideColumn>
        <ColumnDivider orientation="vertical" decorative />
        <MainColumn>
          <TasksSkeleton />
          <Divider decorative />
          <LastOrdersPreviewSkeleton />
          <Divider decorative />
          <ClubBSkeleton />
        </MainColumn>
      </CardContainer>
    </AccountContainer>
  );
}
