/* eslint-disable camelcase */
import { FormEvent, useCallback, useEffect } from "react";

import {
  accountSharedService,
  noteSharedService,
  useDefaultAnalyticsData,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { SCREEN_NAME } from "@/config/constants";
import analytics from "@/config/typewriter";

export function useNotes() {
  const defaultData = useDefaultAnalyticsData();
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const noteService = noteSharedService();
  const {
    upsertNoteStatus: { isLoading: isLoadingUpsertNote },
    getNoteStatus: { isLoading: isLoadingGetNote, error: getNoteError },
    customerNotes,
  } = useSharedUnit(noteService);

  const handleGetNote = useCallback(() => {
    if (accountId && vendorId) {
      noteService.getNote({
        accountId,
        vendorId,
      });
    }
  }, [accountId, noteService, vendorId]);

  useEffect(handleGetNote, [handleGetNote]);

  const handleUpsertNote = () => {
    noteService.upsertNote({
      accountId,
      vendorId,
      customerNotes,
    });
    trackCustomerNoteChanged(customerNotes.note);
  };

  const trackCustomerNoteChanged = useCallback(
    (noteUpdated) => {
      analytics.customerNotesUpdated({
        ...defaultData,
        screen_name: SCREEN_NAME,
        component_name: "Notes",
        note_updated: noteUpdated,
      });
    },
    [defaultData],
  );
  const handleChangeCustomerNotes = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      noteService.setCustomerNotes({
        ...customerNotes,
        note: event.currentTarget.value,
      });
    },
    [customerNotes, noteService],
  );

  return {
    getNoteError,
    customerNotes,
    handleGetNote,
    handleUpsertNote,
    isLoadingGetNote,
    isLoadingUpsertNote,
    handleChangeCustomerNotes,
  };
}
