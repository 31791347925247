/* eslint-disable no-console */
import React, { createContext, useCallback, useContext, useState } from "react";

import { ClassificationEnum } from "@bees-grow-shared/services";

type ModalType = ClassificationEnum.ACTIVATE | ClassificationEnum.INACTIVATE;

interface OpenModalProps {
  type: ModalType;
  phone: string;
  onClick: () => void;
}

interface ModalDataProps {
  type?: ModalType;
  phone?: string;
  onClick?: () => void;
}

interface PhoneActionModalContextProps {
  isModalOpen: boolean;
  modalData?: ModalDataProps;
  handleOpenModal: ({ type, phone }: OpenModalProps) => void;
  handleCloseModal: () => void;
}

const PhoneActionModalContext = createContext<
  PhoneActionModalContextProps | undefined
>(undefined);

export const PhoneActionModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<ModalDataProps | null>(null);

  const handleOpenModal = useCallback(
    ({ type, phone, onClick }: OpenModalProps) => {
      setModalData({
        type,
        phone,
        onClick,
      });
      setIsModalOpen(true);
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <PhoneActionModalContext.Provider
      value={{
        isModalOpen,
        handleOpenModal,
        modalData,
        handleCloseModal,
      }}
    >
      {children}
    </PhoneActionModalContext.Provider>
  );
};

export const usePhoneActionModal = (): PhoneActionModalContextProps => {
  const context = useContext(PhoneActionModalContext);
  if (!context) {
    throw new Error(
      "usePhoneActionModal must be used within a PhoneActionModalProvider",
    );
  }
  return context;
};
