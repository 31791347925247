import { tokens } from "@bees-grow-shared/theme";
import {
  Heading,
  IconButton as IconButtonHexa,
  Modal as ModalHexa,
} from "@hexa-ui/components";
import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const Modal = styled(ModalHexa.Root)``;

export const IconButton = styled(IconButtonHexa)`
  background-color: ${tokens.color.component.bg.button.tertiary.default};
  border: none;

  &:hover {
    box-shadow: none;
    background-color: ${tokens.color.flavor.brand.grow["grow-70"]};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeadingModal = styled(Heading)`
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  color: ${tokens.color.foundation.txt.default};
`;
