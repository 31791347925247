import { useIntl } from "react-intl";

import { ChevronDown, ChevronUp } from "@hexa-ui/icons";

import * as Styles from "./PhoneRankHeader.styles";

interface PhoneRankHeaderProps {
  isDropdownOpen: boolean;
  toggleDropdown: (value: boolean | ((prev: boolean) => boolean)) => void;
  hasError: boolean;
}

export default function PhoneRankHeader({
  isDropdownOpen,
  toggleDropdown,
  hasError,
}: Readonly<PhoneRankHeaderProps>) {
  const { formatMessage } = useIntl();

  const handleDropdownToggle = () => {
    toggleDropdown((prev) => !prev);
  };
  return (
    <Styles.CardHeader
      data-has-error={hasError}
      data-testid={"PhoneRankHeader"}
    >
      <Styles.HeaderContent>
        <Styles.Title size="H4" data-testid="PhoneRankHeader-title">
          {formatMessage({ id: "PhoneNumbers.TITLE" })}
        </Styles.Title>
        {!hasError && (
          <Styles.CollapsibleButton
            hasUnderline={false}
            onClick={handleDropdownToggle}
            data-testid="PhoneRankHeader-collapsible-button"
          >
            {isDropdownOpen ? (
              <ChevronUp size="large" />
            ) : (
              <ChevronDown size="large" />
            )}
          </Styles.CollapsibleButton>
        )}
      </Styles.HeaderContent>
    </Styles.CardHeader>
  );
}
