/* eslint-disable no-console */
import { useIntl } from "react-intl";

import { Tooltip } from "@hexa-ui/components";

import * as Styles from "./ActionButton.styles";

interface ActionButtonProps {
  actionIcon: React.ReactElement;
  onClick?: () => void;
}

export default function ActionButton({
  actionIcon,
  onClick,
}: ActionButtonProps) {
  const { formatMessage } = useIntl();
  const translationKey = actionIcon.key?.toUpperCase();

  return (
    <Tooltip
      placement="bottom"
      text={formatMessage({ id: `PhoneNumbers.TOOLTIP.${translationKey}` })}
    >
      {actionIcon.key === "copy_number" ? (
        actionIcon
      ) : (
        <Styles.IconButton
          hovervariant={actionIcon.key}
          variant="secondary"
          icon={() => actionIcon}
          size="small"
          onClick={onClick}
          data-testid={`${ActionButton.name}-button-phone-off`}
        ></Styles.IconButton>
      )}
    </Tooltip>
  );
}
