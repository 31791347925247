import { useIntl } from "react-intl";

import {
  GlobalMissionKeys,
  MissionType,
  useTaskDescription,
  useTaskTitle,
} from "@bees-grow-shared/services";

interface UseTaskParams {
  missionKey: string;
  title: string;
  description: string;
  type: MissionType;
}

interface UseTaskProps {
  titleFormatted: string;
  descriptionFormatted: string;
}

export function useTask({
  missionKey,
  title,
  description,
  type,
}: UseTaskParams): UseTaskProps {
  const { formatMessage } = useIntl();

  const getFormattedMessage = (id: string) => formatMessage({ id });

  const descriptionLocalTranslate = getFormattedMessage(
    "Tasks.Local.Description.DEFAULT",
  );

  const descriptionGlobalTranslate = {
    [GlobalMissionKeys.MARKETPLACE]: getFormattedMessage(
      "Tasks.Global.Description.MARKETPLACE",
    ),
    [GlobalMissionKeys.TOPLINE]: getFormattedMessage(
      "Tasks.Global.Description.TOPLINE",
    ),
  };

  const titleGlobalTranslate = {
    [GlobalMissionKeys.MARKETPLACE]: getFormattedMessage(
      "Tasks.Global.Title.MARKETPLACE",
    ),
    [GlobalMissionKeys.TOPLINE]: getFormattedMessage(
      "Tasks.Global.Title.TOPLINE",
    ),
  };

  const { description: descriptionFormatted } = useTaskDescription({
    missionKey,
    description,
    type,
    defaultLocalTranslate: descriptionLocalTranslate,
    defaultGlobalTranslateMap: descriptionGlobalTranslate,
  });

  const { title: titleFormatted } = useTaskTitle({
    missionKey,
    title,
    type,
    defaultGlobalTranslateMap: titleGlobalTranslate,
  });

  return { titleFormatted, descriptionFormatted };
}
