import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";
import { Paragraph } from "@hexa-ui/components";

import TaskCard from "./components/taskCard/TaskCard";
import TaskHeader from "./components/taskHeader/TaskHeader";
import { useTasks } from "./hooks/useTasks";
import * as Styled from "./Tasks.styles";

const Tasks = () => {
  const { formatMessage } = useIntl();
  const { tasks, isLoading, error, retry, handleGoToTasksClick } = useTasks();

  if (isLoading) return <Loading />;

  if (error) return <ErrorBehavior retry={retry} />;

  return (
    <Styled.Container data-testid="tasks">
      <Styled.TasksHeader>
        <TaskHeader />
        <Styled.Link
          hasUnderline={false}
          onClick={handleGoToTasksClick}
          data-testid="tasks-link"
        >
          {formatMessage({ id: "Tasks.GO_TO_TASKS" })}
        </Styled.Link>
      </Styled.TasksHeader>
      <Styled.TasksContainer>
        {tasks.length ? (
          tasks.map((task, index) => (
            <TaskCard
              key={`task-${task.missionKey}`}
              missionKey={task.missionKey}
              number={index + 1}
              name={task.name}
              description={task.description}
              type={task.type}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </Styled.TasksContainer>
    </Styled.Container>
  );
};

interface ErrorBehaviorProps {
  retry: () => void;
}

const ErrorBehavior = ({ retry }: ErrorBehaviorProps) => {
  const { formatMessage } = useIntl();

  return (
    <Styled.Container>
      <Styled.TasksHeaderError>
        <TaskHeader />
      </Styled.TasksHeaderError>
      <RetryAlert
        message={formatMessage({ id: "Tasks.UNABLE_TO_LOAD" })}
        actionLabel={formatMessage({ id: "Tasks.TRY_AGAIN" })}
        action={retry}
      />
    </Styled.Container>
  );
};

const TaskSkeleton = () => {
  return (
    <Styled.TaskLoader>
      <Styled.TaskTitleLoader />
      <Styled.TaskDescriptionLoader />
    </Styled.TaskLoader>
  );
};

export const Loading = () => {
  return (
    <Styled.ContainerLoader>
      <Styled.TitleLoader data-testid="tasks-skeleton" />
      <Styled.TasksContainerLoader>
        {Array.from({ length: 6 }).map((_, index) => (
          <TaskSkeleton key={`${TaskSkeleton.name}-${index}`} />
        ))}
      </Styled.TasksContainerLoader>
    </Styled.ContainerLoader>
  );
};

export const EmptyState = () => {
  const { formatMessage } = useIntl();
  return <Paragraph>{formatMessage({ id: "Tasks.EMPTY" })}</Paragraph>;
};

export default Tasks;
